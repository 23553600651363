import React from 'react';
import { Container } from 'components/common';
import config from 'data/config';
import ekp from 'assets/ekp.png';
import sps from 'assets/sps.png';
import ss from 'assets/SkladskladovSID.jpg';
import { Wrapper, Flex, Links, Details } from './styles';

export const Footer = () => (
  <Wrapper>
    <Flex as={Container}>
      <Details>
        <h2>{config.legalName}</h2>
        <span>© Vse pravice pridržane | {new Date().getFullYear()}</span>
      </Details>
      <Links>
        <img src={ekp} height={50} alt="Evropski strukturni in investicijski skladi" />
        <img src={ss} width={200} alt="Sklad skladov" />
        <img src={sps} height={50} alt="Slovenski podjetniški sklad" />
      </Links>
    </Flex>
  </Wrapper>
);
