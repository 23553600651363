import styled from 'styled-components';

export const NavLine = styled.div`
  background: #fff;
  position: sticky;
  left: 0;
  top: 0;
  right: 0;
`;
export const Wrapper = styled.div`
  padding: 1.5rem 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const Brand = styled.a`
  img {
    margin: 0;
  }
`;
