import React, { useContext } from 'react';
import { ThemeContext } from 'providers/ThemeProvider';
import Navbar from './Navbar';
import { Wrapper } from './styles';

export const Header = ({ nohome }) => {
  const { theme } = useContext(ThemeContext);
  return (
    <Wrapper theme={theme}>
      <Navbar nohome={nohome} />
    </Wrapper>
  );
};
