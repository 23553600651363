import logo from 'assets/enetra.png';
import { Container } from 'components/common';
import config from 'data/config';
import { Link } from 'gatsby';
import { ThemeContext } from 'providers/ThemeProvider';
import React, { useContext } from 'react';
import styled from 'styled-components';
import NavbarLinks from '../NavbarLinks';
import { Brand, NavLine, Wrapper } from './styles';

const Style = styled(Wrapper)`
  @media (max-width: 600px) {
    flex-direction: column;
  }
`;
const Navbar = ({ nohome }) => {
  const { theme } = useContext(ThemeContext);
  return (
    <NavLine theme={theme}>
      <Style as={Container}>
        <Brand as={Link} to="/" theme={theme}>
          <img src={logo} height="30" alt={config.legalName} />
        </Brand>
        <NavbarLinks nohome={nohome} />
      </Style>
    </NavLine>
  );
};

export default Navbar;
