module.exports = {
  defaultTitle: 'Enetra',
  logo: '/static/favicon/favicon-512.png',
  author: 'Enetra d.o.o.',
  url: 'https://enetra.si',
  legalName: 'Enetra d.o.o.',
  defaultDescription: 'Veleprodaja električne energije na področju celotne Evropske unije',
  googleAnalyticsID: 'xxx',
  themeColor: '#37B809',
  backgroundColor: '#37B809',
  address: {
    city: 'Ljubljana',
    region: 'Ljubljana',
    country: 'Slovenia',
    zipCode: '1000',
  },
  contact: {
    email: 'email',
    phone: 'phone number',
  },
  foundingDate: '2018',
};
