import React, { useContext } from 'react';
import { Link } from 'gatsby';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import { ThemeContext } from 'providers/ThemeProvider';
import ToggleTheme from 'components/theme/Header/ToggleTheme';

import { Wrapper } from './styles';

const NavbarLinks = ({ nohome }) => {
  const { theme } = useContext(ThemeContext);
  const homeLinks = (
    <>
      <AnchorLink href="#about">O trgovanju</AnchorLink>
      <AnchorLink href="#consult">Svetovanje</AnchorLink>
      <AnchorLink href="#contact">Kontakt</AnchorLink>
    </>
  );
  const nohomeLinks = (
    <>
      <Link to="/#about">O trgovanju</Link>
      <Link to="/#consult">Svetovanje</Link>
      <Link to="/#contact">Kontakt</Link>
    </>
  );
  return (
    <Wrapper theme={theme}>
      {nohome ? nohomeLinks : homeLinks}
      <ToggleTheme />
    </Wrapper>
  );
};

export default NavbarLinks;
